.current-next {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.li-small {
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 1.5;
}

.current,
.next {
  flex: 1; /* Ensure both divs take up equal space */
  margin: 0 10px; /* Add a margin to create space between the two lists */
}

/* .next {
  position: relative;
  right: 10%;
} */

ul {
  margin-top: 8px;
  margin-bottom: 0px;
}

ul ul {
  margin-top: 0px;
  margin-bottom: 8px;
  /* padding-left: 15px; */
}

h2 {
  margin-top: 16px;
  margin-bottom: 8px;
}

ul li {
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.5;
}

p {
  margin-bottom: 1.5em;
  /* background-color: #acf08f; */
}

a {
  color: #000000;
}

a:hover {
  color: rgba(35, 152, 163, 255);
}

.social-image:hover {
  filter: brightness(0) saturate(100%) hue-rotate(0deg) sepia(1) saturate(10)
    hue-rotate(-50deg);
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the animation to the text */
.fade-in-text {
  opacity: 0;
  animation: fadeInText 0.5s ease-in 0s forwards;
}

.fade-in-text-delay {
  opacity: 0;
  animation: fadeInText 0.5s ease-in 1.5s forwards; /* 2s delay before starting */
}

.fade-in-text-delay-2 {
  opacity: 0;
  animation: fadeInText 0.5s ease-in 3s forwards; /* 2s delay before starting */
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.youtube-display {
  opacity: 1; /* Default opacity */
}

.youtube-vid {
  /* position: "absolute"; */
  left: 2%;
  top: 25%;
  width: 60%;
  /* width: 100px; */
  height: auto;
}

.page-display {
  background-color: antiquewhite;
  display: flex;
  position: relative;
  width: 100%;
}

.main-content-wrapper {
  height: 100%;
  width: 100%;
  /* background-color: #a696c0; */
  background-color: antiquewhite;
  display: flex;
  flex-direction: row;
}

.socials {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 8%;
  text-align: right;
}

.main-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  left: 8%;
  width: 92%;
  height: 100%;
  /* position: relative; */
  background-color: rgb(255, 225, 0);
}

.body-wrapper {
  /* display: flex; */
  /* flex-direction: column; */
  /* flex: 1; */
  padding: 24px;
  box-sizing: border-box;
  /* background-color: #5573a9; */
  background-color: antiquewhite;
  height: 15%;
}

.body-wrapper.clearfix {
  /* flex: 1; */
  /* height: 100%; */
  background-color: #3d3c87;
}

h1 {
  text-align: left;
  font-size: 36px;
}

nav {
  display: flex;
  /* background-color: purple; */
  background-color: antiquewhite;

  flex-direction: column;
  width: 15%;
  /* position: relative; */
  /* left: 10%; */
}

.clearfix::after {
  height: 100%;
  /* background-color: gold; */
  content: "clearfix after is here";
  display: table;
  clear: both;
}

.ul.nav {
  box-sizing: border-box;
  border: 1px solid rgba(35, 152, 163, 255);
  /* background-color: #a5c4ff; */
  background-color: antiquewhite;
  padding: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.page-text {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 16px;
  padding-left: 60px;
  padding-right: 68px;
}

.video-display-container {
  position: relative;
  flex-direction: column;
  width: 100%;
  /* flex: 1; */
  height: 80%;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Courier New", monospace;
  margin: 0;
  padding: 0;
  /* text-align: center; */
  /* background-color: rgb(238, 191, 191); */
  background-color: antiquewhite;
}

header {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
}

main {
  padding: 20px;
  background-color: #acf08f;
}

.filter-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

button {
  padding: 4px 16px;
  font-family: "Courier New", Courier, monospace;
  /* background-color: #007bff; */
  color: #000000;
  border: 1px solid black;
  /* border-radius: 5px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.choreo {
  background-color: rgb(207, 93, 122);
}

.choreo:hover,
.choreo-gone:hover {
  background-color: rgb(231, 154, 173);
}

.choreo-gone {
  background-color: rgb(243, 201, 211);
}

.fxn {
  background-color: rgb(140, 109, 178);
}

.fxn:hover,
.fxn-gone:hover {
  background-color: rgb(180, 158, 206);
}

.fxn-gone {
  background-color: rgb(208, 194, 223);
}

.teach {
  background-color: rgb(243, 181, 0);
}

.teach:hover,
.teach-gone:hover {
  background-color: rgb(255, 219, 110);
}

.teach-gone {
  background-color: rgb(238, 219, 160);
}

.other {
  background-color: rgb(88, 140, 183);
}

.other:hover,
.other-gone:hover {
  background-color: rgb(133, 180, 219);
}

.other-gone {
  background-color: rgb(191, 214, 234);
}

footer {
  background-color: #333;
  color: #fff;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.row-container {
  display: flex;
  align-items: top;
  justify-content: center;
  /* background-color: rgb(185, 230, 173); */
  box-sizing: border-box;
  flex-grow: 1;
  flex-direction: row;
}

.row-block {
  /* display: flex; */
  flex-direction: column;
  align-items: top;
  box-sizing: border-box;
  /* padding: 24px; */
  width: 50%;
  overflow-wrap: break-word;
}

.proof-output {
  box-sizing: border-box;
  /* background-color: rgb(132, 209, 110);
    margin: 16px; */
}

.text-box {
  font-family: Arial, Helvetica, sans-serif;
  margin: 16px;
  padding: 0;
  text-align: left;
  background-color: #3d3c87;
}

textarea {
  width: calc(90%);
  height: 200px;
  padding: 10px;
  /* background-color: #f1f1f1; */
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  /* color: #333; */
  resize: vertical;
  overflow: auto;
  white-space: pre-wrap; /* Keeps formatting in the text area */
  word-wrap: break-word; /* Allows long lines to be wrapped */
}
