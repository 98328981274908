.current-next {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.li-small {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.5;
}

.current, .next {
  flex: 1;
  margin: 0 10px;
}

ul {
  margin-top: 8px;
  margin-bottom: 0;
}

ul ul {
  margin-top: 0;
  margin-bottom: 8px;
}

h2 {
  margin-top: 16px;
  margin-bottom: 8px;
}

ul li {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
}

p {
  margin-bottom: 1.5em;
}

a {
  color: #000;
}

a:hover {
  color: #2398a3;
}

.social-image:hover {
  filter: brightness(0) saturate() hue-rotate() sepia() saturate(10) hue-rotate(-50deg);
}

@keyframes fadeInText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in-text {
  opacity: 0;
  animation: .5s ease-in forwards fadeInText;
}

.fade-in-text-delay {
  opacity: 0;
  animation: .5s ease-in 1.5s forwards fadeInText;
}

.fade-in-text-delay-2 {
  opacity: 0;
  animation: .5s ease-in 3s forwards fadeInText;
}

.fade-out {
  opacity: 0;
  transition: opacity .5s ease-out;
}

.fade-in {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.youtube-display {
  opacity: 1;
}

.youtube-vid {
  width: 60%;
  height: auto;
  top: 25%;
  left: 2%;
}

.page-display {
  background-color: #faebd7;
  width: 100%;
  display: flex;
  position: relative;
}

.main-content-wrapper {
  background-color: #faebd7;
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: flex;
}

.socials {
  text-align: right;
  flex-direction: column;
  width: 8%;
  height: 100%;
  display: flex;
}

.main-content {
  background-color: #ffe100;
  flex-direction: row;
  flex: 1;
  width: 92%;
  height: 100%;
  display: flex;
  left: 8%;
}

.body-wrapper {
  box-sizing: border-box;
  background-color: #faebd7;
  height: 15%;
  padding: 24px;
}

.body-wrapper.clearfix {
  background-color: #3d3c87;
}

h1 {
  text-align: left;
  font-size: 36px;
}

nav {
  background-color: #faebd7;
  flex-direction: column;
  width: 15%;
  display: flex;
}

.clearfix:after {
  content: "clearfix after is here";
  clear: both;
  height: 100%;
  display: table;
}

.ul.nav {
  box-sizing: border-box;
  background-color: #faebd7;
  border: 1px solid #2398a3;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 10px;
  font-size: 16px;
  display: flex;
}

.page-text {
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 16px 68px 16px 60px;
  display: flex;
  position: relative;
}

.video-display-container {
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 80%;
  position: relative;
}

html {
  height: 100%;
}

body {
  background-color: #faebd7;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Courier New, monospace;
}

header {
  color: #fff;
  background-color: #333;
  padding: 10px 0;
}

main {
  background-color: #acf08f;
  padding: 20px;
}

.filter-button-container {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

button {
  color: #000;
  cursor: pointer;
  border: 1px solid #000;
  padding: 4px 16px;
  font-family: Courier New, Courier, monospace;
  transition: background-color .3s;
}

.choreo {
  background-color: #cf5d7a;
}

.choreo:hover, .choreo-gone:hover {
  background-color: #e79aad;
}

.choreo-gone {
  background-color: #f3c9d3;
}

.fxn {
  background-color: #8c6db2;
}

.fxn:hover, .fxn-gone:hover {
  background-color: #b49ece;
}

.fxn-gone {
  background-color: #d0c2df;
}

.teach {
  background-color: #f3b500;
}

.teach:hover, .teach-gone:hover {
  background-color: #ffdb6e;
}

.teach-gone {
  background-color: #eedba0;
}

.other {
  background-color: #588cb7;
}

.other:hover, .other-gone:hover {
  background-color: #85b4db;
}

.other-gone {
  background-color: #bfd6ea;
}

footer {
  color: #fff;
  background-color: #333;
  width: 100%;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
}

.row-container {
  align-items: top;
  box-sizing: border-box;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.row-block {
  align-items: top;
  box-sizing: border-box;
  overflow-wrap: break-word;
  flex-direction: column;
  width: 50%;
}

.proof-output {
  box-sizing: border-box;
}

.text-box {
  text-align: left;
  background-color: #3d3c87;
  margin: 16px;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

textarea {
  resize: vertical;
  white-space: pre-wrap;
  word-wrap: break-word;
  width: 90%;
  height: 200px;
  padding: 10px;
  overflow: auto;
}
/*# sourceMappingURL=index.e1627048.css.map */
